<template>
    <div id="group-member">
        <div class="row" v-if="typeof data.unapprove !== 'undefined' && data.unapprove.length > 0">
            <div class="col-md-12 mb-3"><strong>Waiting Approval</strong></div>
            <div class="col-md-3 col-sm-4" v-for="item in data.unapprove" :key="item.id">
                <div class="card p-2 mb-4 p-3">
                    <center>
                        <img src="@/assets/images/avatars/avatar-5.jpg" class="w-10 h-10 rounded-full mb-2">
                        <span class="badge badge-primary" v-if="item.is_admin === 1">Admin</span>
                        <h3 class="mt-1"><strong>{{ item.user.fullname }}</strong></h3>
                        <!-- <a href="#" class="mt-2 d-block mb-2 link-underline"><small>View Profile</small></a> -->
                        <button v-if="detail.is_admin || detail.is_owner" class="btn btn-primary mt-2" @click.prevent="approveMember(item.user.id)">Approve</button>
                    </center>
                </div>
            </div>
            <div class="col-md-12">
                <hr>
                <br>
            </div>
        </div>
        <div class="row equal-cols">
            <div class="col-md-3 col-sm-4 col-6" v-for="item in data.member" :key="item.id">
                <div class="card mb-4 pt-3 pb-1 overflow-visible">
                    <center>
                        <b-dropdown v-if="detail.is_admin || detail.is_owner" class="flex items-center btn-more" variant="link" toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                                <Icon icon="akar-icons:more-vertical" class="text-right" />
                            </template>
                            <b-dropdown-item v-if="item.is_admin" @click="removeFromAdmin(item.user.id)">Remove from Admin</b-dropdown-item>
                            <b-dropdown-item v-if="!item.is_admin" @click="setAsAdmin(item.user.id)">Set As Admin</b-dropdown-item>
                        </b-dropdown>
                        <div class="p-2">
                            <img :src="item.user.images !== '' ? item.user.images : require('@/assets/images/avatars/avatar-5.jpg')" class="w-10 h-10 rounded-full mb-2">
                            <span class="badge badge-primary small" v-if="item.is_admin === 1 && item.is_owner === 0">Admin</span>
                            <span class="badge badge-primary small" v-if="item.is_admin === 1 && item.is_owner === 1">Owner</span>
                            <router-link :to="{ name: 'Profile', params: {code: item.user.username } }" >
                                <h3 class="mt-1"><strong>{{ item.user.fullname }}</strong></h3>
                            </router-link>
                        </div>
                    </center>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Icon } from '@iconify/vue2'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'group_member',
        components: { Icon },
        computed: {
            ...mapState('members', ['loading', 'data']),
            ...mapState('groups', {
                detail: state => state.selected.data,
                detailLoading: state => state.selected.loading
            })
        },
        methods: {
            ...mapActions('members', ['fetch']),
            ...mapActions('groups', ['approve', 'setAdmin', 'removeAdmin']),
            approveMember (userId) {
                this.approve({ groupId: this.detail.id, userId })
                    .then(() => {
                        this.fetch(this.$route.params.code)
                    })
            },
            setAsAdmin (userId) {
                this.setAdmin({ groupId: this.detail.id, userId })
                    .then(() => {
                        this.fetch(this.$route.params.code)
                    })
            },
            removeFromAdmin (userId) {
                this.removeAdmin({ groupId: this.detail.id, userId })
                    .then(() => {
                        this.fetch(this.$route.params.code)
                    })
            }
        },
        created () {
            this.fetch(this.$route.params.code)
        }
    }
</script>
